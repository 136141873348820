import { QueryClient } from 'react-query'

// let baseURL = "http://192.168.8.29:9001";
let baseURL = 'https://iconme-server.partytest.link'

// deprecated
if (process.env.GATSBY_STORE_URL) {
  baseURL = process.env.GATSBY_STORE_URL
}

// takes precedence over GATSBY_STORE_URL
if (process.env.GATSBY_MEDUSA_BACKEND_URL) {
  baseURL = process.env.GATSBY_MEDUSA_BACKEND_URL
}

if (typeof window !== 'undefined') {
  const str = window.location.origin
  if (str.includes('localhost')) {
    baseURL = `http://localhost:9000`;
  }
  if (str.includes('iconme-web.partytest.link')) {
    baseURL = `https://iconme-server.partytest.link`;
  }
  if (str.includes('iconme-web.partypre.link')) {
    baseURL = `https://iconme-server.partypre.link`
  }
  if (str.includes('iconme-web.metaicons.link')) {
    baseURL = `https://iconme-server.gamehubs.link`
  }
  // 福州
  if (str.includes('iconme-web.minerhubs.link')) {
    baseURL = `https://iconme-server.minerhubs.link`
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 90000,
      retry: 1,
    },
  },
})

export { baseURL, queryClient }
